import React, { useEffect, useState, createContext, useMemo } from "react";
import { AnimatePresence, motion } from "framer-motion";
import "../assets/css/main.scss";
import { useStaticQuery, graphql } from "gatsby";
import pImage from "../images/blue-p.svg";
import resslyImage from "../images/ressly.svg";
import { defineMessages, useIntl } from "gatsby-plugin-react-intl";
import Footer from "../components/footer";
import Nav from "../components/nav";
import Head from "../components/head";
import Cookies from "../components/cookies";
import SEO from "../components/seo";
import { useLocation, globalHistory, Rout } from "@reach/router";
import { Helmet } from "react-helmet";
export const NavContext = createContext({
  navData: [],
  setNavData: () => {},
});

function Layout({ pageContext, children }) {
  const [load, setLoad] = useState(true);
  const [navData, setNavData] = useState({});
  const navDatas = useMemo(() => ({ navData, setNavData }), [navData]);
  const lang = useIntl();
  const location = useLocation();
  const data = useStaticQuery(query);
  const readyData = data.allStrapiSeo.nodes[0].pageSeo;
  const currentLang = lang.locale;
  const path = pageContext.intl.originalPath.substring(1).split("/");
  const finalPath = path[0] === "" ? "index" : path[0];
  const seo = readyData
    .filter(({ pageName }) => pageName === finalPath)?.[0]
    ?.languageSeo.filter(({ language }) =>
      language === currentLang ? currentLang : "en"
    );
  useEffect(() => {
    const interval = setTimeout(() => {
      setLoad(false);
    }, 1800);

    return () => clearInterval(interval);
  }, []);

  
  useEffect(() => {
    let check = false;
    window.scrollTo(0, 0);
  }, [location.pathname]);

  return (
    <>
      {typeof seo === "object" && Object.keys(seo).length > 0 && (
        <>
          <SEO seo={seo[0]} />
        </>
      )}
      <AnimatePresence>
        {load ? (
          <div className="loader">
            <motion.div
              className="loader__wrapper"
              transition={{ duration: 0.8 }}
              initial={{ width: 40 }}
              animate={{ width: 270 }}
            >
              <img src={pImage} className="pLetter" />
              <div className="loader__text">
                <img src={resslyImage} className="restLogo" />
              </div>
              <motion.span
                className="dot"
                animate={{ scale: 500 }}
                transition={{ delay: 1, duration: 1 }}
              />
            </motion.div>
          </div>
        ) : (
          <motion.div
            initial={{
              opacity: 0,
            }}
            exit={{ opacity: 0 }}
            transition={{ duration: 1.5 }}
            animate={{
              opacity: 1,
            }}
          >
            <NavContext.Provider value={navDatas}>
              <Nav />
              <div style={{ minHeight: "calc(100vh )" }}>{children}</div>
              <Cookies />
              <Footer />
            </NavContext.Provider>
          </motion.div>
        )}
      </AnimatePresence>
    </>
  );
}

const query = graphql`
  query MyQuery {
    allStrapiSeo {
      nodes {
        pageSeo {
          pageName
          languageSeo {
            language
            metaDescription
            metaTitle
          }
          pageName
        }
      }
    }
  }
`;

export default Layout;
