import { changeLocale, useIntl } from "gatsby-plugin-react-intl";
import React, { useState } from "react";
import "../assets/css/flags.scss";
import EN from "../images/gb.svg";
import PL from "../images/pl.svg";

function Languages({ color, background }) {
  const [show, setShow] = useState(false);
  const langs = [
    {
      locale: "pl",
      img: PL,
    },
    {
      locale: "en",
      img: EN,
    },
  ];
  let langsArray = [];
  let key = 1;
  const intl = useIntl();
  langs.forEach((item) => {
    if (intl.locale === item.locale) {
      langsArray[0] = item;
    } else {
      langsArray[key] = item;
      key++;
    }
  });

  const FlagItem = ({ img, title, onClick }) => {
    return (
      <button
        className="flags__singleFlag"
        onClick={() => {
          changeLocale(title);
          setShow(!show);
        }}
        style={{ ...(color ? { color: color } : {}) }}
      >
        <img src={img} className="flags__flag" />
        {title}
      </button>
    );
  };
  return (
    <div className={`flags`}>
      <FlagItem img={langsArray[1].img} title={langsArray[1].locale} />
      {/* {show && (
        <div className="flags__another">
          {langsArray.map(({ locale, img }, key) => {
            if (key > 0) {
              return <FlagItem img={img} title={locale} />;
            }
          })}
        </div>
      )} */}
    </div>
    // <div
    //   className="flags"
    //   style={
    //     {
    //       // overflow: window.innerWidth < 1200 || show ? "initial" : "hidden",
    //     }
    //   }
    // >
    //   <button className="flags__button">
    //     <p style={{ ...(color ? { color: color } : {}) }}>PL</p>
    //     <img src={PL} className="flags__flag" />
    //   </button>
    //   <div>
    //     {langsArray.map(({ locale, img }, key) => (
    //       <button
    //         key={key}
    //         className="flags__button"
    //         onClick={() => {
    //           changeLocale(locale);
    //           setShow(!show);
    //         }}
    //       >
    //         <p style={{ ...(color ? { color: color } : {}) }}>
    //           {locale.toUpperCase()}
    //         </p>
    //         <img src={img} className="flags__flag" />
    //       </button>
    //     ))}
    //   </div>
    // </div>
  );
}
export default Languages;
