import { Splide, SplideSlide } from "@splidejs/react-splide";
import "@splidejs/splide/dist/css/splide.min.css";
import { AnimatePresence, motion } from "framer-motion";
import { Link, useIntl } from "gatsby-plugin-react-intl";
import request from "graphql-request";
import parse from "html-react-parser";
import React, { useEffect, useState } from "react";
import { Container } from "react-bootstrap";
import "../assets/css/footer.scss";
import pressly_bg from "../images/pressly-22.svg";
import pressly_1 from "../images/presslyy.svg";

function Footer({ contactUs }) {
  const [show, setShow] = useState(false);
  const [offer, setOffer] = useState([]);
  const [nav, setNav] = useState([]);
  const [contact, setContact] = useState([]);
  const [buttons, setButtons] = useState({});
  const [footer, setFooter] = useState({});
  const strapiHost = process.env.GATSBY_API_URL;
  const intl = useIntl();
  const fetch = async () => {
    const res = await request(
      process.env.GATSBY_GRAPHQL_URL,
      `{
        offer(locale:"${intl.locale}"){
          offer_single{
            title
            link
          }
        }
        navigations(locale:"${intl.locale}"){
          title
          link
        }
        contact(locale:"${intl.locale}"){
          contact_single{
            img_white{
              url
            }
            description_single
            url
          }
        }
        button(locale:"${intl.locale}"){
          askforfulloffer
          contact
        }
        footer(locale:"${intl.locale}"){
          servicesTitle
          privacyTitle
          privacyLinks{
            privacyTitle
            privacyLink
          }
        }
      }
      `
    );
    return res;
  };

  useEffect(() => {
    fetch().then((items) => {
      setButtons(items.button);
      setContact(items.contact.contact_single);
      setNav(items.navigations);
      setOffer(items.offer.offer_single);
      setFooter(items.footer);
      setShow(true);
    });
  }, [intl]);
  return (
    <AnimatePresence>
      {show && (
        <motion.div
          className="footer"
          initial={{
            y: "+100%",
          }}
          animate={{
            y: 0,
          }}
          exit={{
            y: "+100%",
          }}
        >
          <div className="footer__bg-blob" />
          <Container className="footer__wrapper">
            <div className="footer__left">
              <div className="footer__logo">
                <div className="footer__logo-filter" />
                <div className="footer__logo-slider">
                  <Splide
                    options={{
                      direction: "ttb",
                      width: 150,
                      height: 250,
                      gap: 10,
                      perPage: 6,
                      breakpoints: {
                        992: {
                          perPage: 3,
                          width: 150,
                          height: 130,
                        },
                      },
                      type: "loop",
                      pagination: false,
                      arrows: false,
                      cover: true,
                      focus: "center",
                      autoplay: true,
                      interval: 1,
                      speed: 800,
                      pauseOnHover: false,
                      pauseOnFocus: false,
                    }}
                  >
                    {[1, 2, 3, 4, 5, 6, 1, 2, 3, 4, 5, 6, 1, 2, 3, 4, 5, 6].map(
                      (item, key) => (
                        <SplideSlide key={key}>
                          <img src={pressly_bg} />
                        </SplideSlide>
                      )
                    )}
                  </Splide>
                </div>
                <div className="footer__logo-filterBot" />
                <div className="footer__logo-wrapper">
                  <img src={pressly_1} className="footer__logo-title" />
                </div>
              </div>
            </div>
            <div className="footer__right">
              {[
                {
                  title: "Pressly",
                  items: [...nav, { title: buttons.contact, link: "/contact" }],
                },
                {
                  title: footer.servicesTitle,
                  items: [...offer],
                },
                {
                  title: footer.privacyTitle,
                  items: footer.privacyLinks.map(
                    ({ privacyTitle, privacyLink }) => {
                      return { title: privacyTitle, link: privacyLink };
                    }
                  ),
                },
              ].map(({ title, items }, key) => (
                <div className="footer__nav-wrapper" key={key}>
                  <h3 className="footer__nav-title">{title}</h3>
                  <ul className="footer__nav">
                    {items.map(({ title, link }, index) => (
                      <li className="footer__nav-item" key={index}>
                        {title && (
                          <Link to={link} className="footer__nav-item__link">
                            {title}
                          </Link>
                        )}
                      </li>
                    ))}
                  </ul>
                </div>
              ))}
              <div className="footer__contact-wrapper">
                <ul className="footer__contact-links">
                  {contact.map(
                    ({ img_white, description_single, url, target }, index) => (
                      <li key={index} className="footer__contact-item">
                        <a
                          href={url}
                          target={target}
                          className="footer__contact-link"
                        >
                          <img
                            src={`${strapiHost}${img_white.url}`}
                            className="footer__contact-icon"
                          />
                          {parse(description_single)}
                        </a>
                      </li>
                    )
                  )}
                </ul>
              </div>
            </div>
          </Container>
        </motion.div>
      )}
    </AnimatePresence>
  );
}

export default Footer;
