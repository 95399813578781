import { useLocation } from "@reach/router";
import { AnimatePresence, motion } from "framer-motion";
import { Link, useIntl } from "gatsby-plugin-react-intl";
import { globalHistory } from "@reach/router";
import request from "graphql-request";
import { Sling as Hamburger } from "hamburger-react";
import React, { useContext, useEffect, useState } from "react";
import { Col, Container, Row } from "react-bootstrap";
import "../assets/css/navbar.scss";
import Languages from "./languages";
import { NavContext } from "../layouts";

function Nav(p) {
  const [show, setShow] = useState(false);
  const [scrollPosition, setScrollPosition] = useState(0);
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState({});
  const [buttons, setButtons] = useState({});
  const [color, setColor] = useState("");
  const [background, setBackground] = useState("");
  const { navData, setNavData } = useContext(NavContext);
  const location = useLocation();
  const intl = useIntl();
  const handleScroll = () => {
    const position = window.pageYOffset;
    setScrollPosition(position);
  };
  const handleResize = () => {
    if (window.innerWidth > 1200) {
      setShow(true);
    } else {
      setShow(false);
    }
  };

  useEffect(() => {
    const fetch = async () => {
      const res = await request(
        process.env.GATSBY_GRAPHQL_URL,
        `{
          navigations(locale: "${intl.locale}"){
              title
              link
          }
          button(locale: "${intl.locale}"){
            contact
          }
        }`
      );
      return res;
    };
    fetch().then((items) => {
      setData(items.navigations);
      setButtons(items.button);
      setLoading(false);
    });
  }, [intl]);
  useEffect(() => {
    setNavData({});
    handleResize();
    document.body.style.position = "inherit";
    window.addEventListener("resize", handleResize);
    window.addEventListener("scroll", handleScroll);
  }, [location]);
  return (
    <AnimatePresence>
      {!loading && (
        <motion.header
          initial={{
            y: "-100%",
          }}
          animate={{
            y: 0,
          }}
          exit={{
            y: "-100%",
          }}
          className="nav"
          style={{
            ...(navData.navBg !== "" && window.innerWidth > 1200
              ? { background: navData.navBg }
              : scrollPosition < 10 && !show
              ? { background: navData.navBg }
              : {}),
          }}
        >
          <Container>
            <Row className="nav__wrapper">
              <Col
                className="nav__container d-flex justify-content-between align-items-center"
                xs={12}
                xl={1}
              >
                <Link
                  to="/"
                  className="nav__container__logo"
                  style={{
                    ...(navData.color !== "" && window.innerWidth > 1200
                      ? { color: navData.color }
                      : scrollPosition < 10 && !show
                      ? { color: navData.color }
                      : {}),
                  }}
                >
                  Pressly.
                </Link>
                <button
                  className="d-block d-xl-none nav__container__toggle-nav"
                  onClick={() => {
                    setShow(!show);
                  }}
                  style={{
                    ...(navData.color !== "" && window.innerWidth > 1200
                      ? { color: navData.color }
                      : scrollPosition < 10 && !show
                      ? { color: navData.color }
                      : {}),
                  }}
                >
                  <Hamburger toggled={show} toggle={setShow} />
                </button>
              </Col>
              {/* EXPAND NAVIGATION */}
              {show && (
                <Col xs={12} xl={11} className="nav__rwd">
                  <Row className="d-flex align-items-center justify-content-center h-100 flex-column flex-xl-row">
                    <Col className="nav__container" xs={12} xl={10}>
                      <ul className="nav__container__links d-flex flex-column align-center justify-content-around flex-xl-row">
                        {data.map(({ title, link }, key) => (
                          <li
                            key={key}
                            className={`nav__container__links__link justify-content-center ${
                              location.pathname.includes(link)
                                ? "nav__container__links__link--active"
                                : ""
                            }`}
                          >
                            <Link
                              to={link}
                              style={{
                                ...(navData.color !== "" &&
                                window.innerWidth > 1200
                                  ? { color: navData.color }
                                  : scrollPosition < 10 && !show
                                  ? { color: navData.color }
                                  : {}),
                              }}
                            >
                              {title}
                            </Link>
                          </li>
                        ))}
                        <li className="nav__container__links__link justify-content-center">
                          <Languages
                            color={
                              navData.color !== "" && window.innerWidth > 1200
                                ? navData.color
                                : scrollPosition < 10 && !show
                                ? navData.color
                                : null
                            }
                            background={
                              navData.navBg !== "" && window.innerWidth > 1200
                                ? navData.navBg
                                : scrollPosition < 10 && !show
                                ? navData.navBg
                                : null
                            }
                          />
                        </li>
                      </ul>
                    </Col>
                    <Col
                      className="nav__container justify-content-center justify-content-xl-end gap-5"
                      xs={12}
                      xl={2}
                    >
                      <Link to="/contact">
                        <button
                          className="btn btn-primary btn-contact btn-sm"
                          type="button"
                        >
                          {buttons.contact}
                        </button>
                      </Link>
                    </Col>
                  </Row>
                </Col>
              )}
            </Row>
          </Container>
        </motion.header>
      )}
    </AnimatePresence>
  );
}
export default Nav;
