exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-career-js": () => import("./../../../src/pages/career.js" /* webpackChunkName: "component---src-pages-career-js" */),
  "component---src-pages-collab-js": () => import("./../../../src/pages/collab.js" /* webpackChunkName: "component---src-pages-collab-js" */),
  "component---src-pages-contact-js": () => import("./../../../src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-cookies-js": () => import("./../../../src/pages/cookies.js" /* webpackChunkName: "component---src-pages-cookies-js" */),
  "component---src-pages-gamification-js": () => import("./../../../src/pages/gamification.js" /* webpackChunkName: "component---src-pages-gamification-js" */),
  "component---src-pages-generator-js": () => import("./../../../src/pages/generator.js" /* webpackChunkName: "component---src-pages-generator-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-offer-js": () => import("./../../../src/pages/offer.js" /* webpackChunkName: "component---src-pages-offer-js" */),
  "component---src-pages-outsourcing-js": () => import("./../../../src/pages/outsourcing.js" /* webpackChunkName: "component---src-pages-outsourcing-js" */),
  "component---src-pages-privacy-js": () => import("./../../../src/pages/privacy.js" /* webpackChunkName: "component---src-pages-privacy-js" */),
  "component---src-pages-realizations-js": () => import("./../../../src/pages/realizations.js" /* webpackChunkName: "component---src-pages-realizations-js" */),
  "component---src-pages-ruby-js": () => import("./../../../src/pages/ruby.js" /* webpackChunkName: "component---src-pages-ruby-js" */),
  "component---src-pages-shops-js": () => import("./../../../src/pages/shops.js" /* webpackChunkName: "component---src-pages-shops-js" */),
  "component---src-pages-soft-js": () => import("./../../../src/pages/soft.js" /* webpackChunkName: "component---src-pages-soft-js" */),
  "component---src-pages-terms-js": () => import("./../../../src/pages/terms.js" /* webpackChunkName: "component---src-pages-terms-js" */),
  "component---src-pages-thanks-for-send-js": () => import("./../../../src/pages/thanksForSend.js" /* webpackChunkName: "component---src-pages-thanks-for-send-js" */),
  "component---src-pages-website-js": () => import("./../../../src/pages/website.js" /* webpackChunkName: "component---src-pages-website-js" */),
  "component---src-templates-blog-js": () => import("./../../../src/templates/blog.js" /* webpackChunkName: "component---src-templates-blog-js" */),
  "component---src-templates-single-post-js": () => import("./../../../src/templates/singlePost.js" /* webpackChunkName: "component---src-templates-single-post-js" */),
  "component---src-templates-single-realization-js": () => import("./../../../src/templates/singleRealization.js" /* webpackChunkName: "component---src-templates-single-realization-js" */)
}

