import React, { useEffect } from "react";
import { Link, useIntl } from "gatsby-plugin-react-intl";
import { AnimatePresence, motion } from "framer-motion";
import cookieImage from "../images/cookie.svg";
import xIcon from "../images/x-icon.svg";
import { CookiesProvider, useCookies } from "react-cookie";
import "../assets/css/cookies.scss";
const Cookies = () => {
  const [cookies, setCookie] = useCookies(["acceptCookieAlert"]);
  const intl = useIntl();
  useEffect(() => {}, []);
  return (
    <CookiesProvider>
      <AnimatePresence>
        {!cookies.acceptCookieAlert && (
          <motion.div
            className="cookies"
            initial={{ opacity: 1 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            transition={{ duration: 0.8 }}
          >
            <div className="cookies__image-container">
              <img src={cookieImage} />
            </div>
            <div className="cookies__desc-container">
              <h2>
                {
                  {
                    pl: <>Pliki cookies</>,
                    en: <>Cookies</>,
                  }[intl.locale]
                }
              </h2>
              <p>
                {
                  {
                    pl: (
                      <>
                        Używamy plików cookie, aby Twoje doświadczenia były
                        lepsze. Zapoznaj się z naszą{" "}
                        <Link to="/privacy">polityką prywatności</Link>
                      </>
                    ),
                    en: (
                      <>
                        We use cookies to keep your experience. check our{" "}
                        <Link to="/privacy">privacy policy</Link>
                      </>
                    ),
                  }[intl.locale]
                }
              </p>
            </div>
            <button
              className="cookies__exit-button"
              onClick={() => {
                setCookie("acceptCookieAlert", 1, { path: "/" });
              }}
            >
              <img src={xIcon} />
            </button>
          </motion.div>
        )}
      </AnimatePresence>
    </CookiesProvider>
  );
};

export default Cookies;
